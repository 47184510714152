(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/detail-view-mobile-handler/assets/javascripts/detail-view-mobile-handler.js') >= 0) return;  svs.modules.push('/components/tipsen/detail-view-mobile-handler/assets/javascripts/detail-view-mobile-handler.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }

const {
  useCallback,
  useRef
} = React;
const {
  useSelector
} = ReactRedux;
const {
  detect
} = svs.core;
const {
  EventTypeField
} = svs.components.sportinfo.common.constants;
const {
  getSportInfoMatchState
} = svs.components.sportinfo.common.matchState;
const {
  BigStatBanner
} = svs.components.sportInfo.bigStatBanner;
const {
  TipsenModal,
  StickyBottom,
  StickyButtonClose,
  StickyButtonIcon,
  ModalHeader
} = svs.components.tipsen.tipsenModal;
const {
  useQueryParam
} = svs.components.lbUtils;
const {
  useHistory,
  useLocation,
  Route,
  useRouteMatch,
  Switch
} = ReactRouterDOM;
const {
  selectSportEvent,
  selectEventStatisticCurrentValueByType
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  useBetEvent
} = svs.components.sportinfo.matchInfoHooks;
const {
  useBranding,
  useGetParticipantsByEvent
} = svs.components.tipsen.hooks;
const {
  useCurrentDrawEvent,
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  LazyGameGuide,
  LazyOdds
} = svs.components.tipsen.sideNav;
const {
  TipsenModuleGameUrl
} = svs.components.sport.tipsenShared;
const {
  ItemsMenu
} = svs.ui.ReactItemsMenu;
const {
  LinkItemRoute
} = svs.components.lbUtils.linkItemRoute;
const {
  TipsenLink
} = svs.components.tipsen.tipsen_link;
const useCloseModal = () => {
  const history = useHistory();
  const location = useLocation();
  const handleClose = () => {
    const search = new URLSearchParams(location.search);
    if (search.has('openStatistic')) {
      search.delete('openStatistic');
    }
    history.push({
      search: "?".concat(search)
    });
  };
  return handleClose;
};
const ModalFooter = _ref => {
  let {
    routesMatch
  } = _ref;
  return React.createElement(StickyBottom, null, React.createElement(StickyButtonClose, {
    to: {
      pathname: routesMatch.url
    }
  }));
};
const DetailsStickyBottom = _ref2 => {
  let {
    closeModal,
    onNavigateNext
  } = _ref2;
  return React.createElement(StickyBottom, {
    hasEvenplacement: false
  }, React.createElement(StickyButtonIcon, {
    icon: "menu-left",
    onClick: () => onNavigateNext(-1)
  }), React.createElement(StickyButtonClose, {
    isInverted: false,
    isTransparent: true,
    onClick: closeModal
  }), React.createElement(StickyButtonIcon, {
    icon: "menu-right",
    onClick: () => onNavigateNext(1)
  }));
};
const defaultSportEvent = {
  participants: [],
  leagueId: undefined,
  matchStart: undefined
};
const gameGuidePaths = ["/".concat(TipsenModuleGameUrl.GAME_GUIDE), "/".concat(TipsenModuleGameUrl.SYSTEM, "/").concat(TipsenModuleGameUrl.GAME_GUIDE), "/".concat(TipsenModuleGameUrl.TIPS_SM, "/").concat(TipsenModuleGameUrl.GAME_GUIDE), "/".concat(TipsenModuleGameUrl.TIPS_EM, "/").concat(TipsenModuleGameUrl.GAME_GUIDE), "/".concat(TipsenModuleGameUrl.SINGLES, "/").concat(TipsenModuleGameUrl.GAME_GUIDE)];
const oddsPaths = ["/".concat(TipsenModuleGameUrl.ODDS)];
const hasQuickBetActive = detect.feature('ft-tipsen-quickbet');
const DetailViewMobileHandler = _ref3 => {
  let {
    drawId,
    hasMaskedOutcomes = false,
    headerComponent,
    extraMenuItems,
    children
  } = _ref3;
  const {
    drawEvents,
    productId,
    drawNumber
  } = useCurrentDraw(drawId);
  const {
    eventNumber,
    matchId
  } = useCurrentDrawEvent(drawId);
  const [productBranding] = useBranding();
  const routesMatch = useRouteMatch();
  const sportEvent = useSelector(state => selectSportEvent(state, matchId)) || defaultSportEvent;
  const {
    eventStatisticsKey
  } = useBetEvent({
    productId,
    drawNumber,
    eventNumber
  });
  const odds = useSelector(state => selectEventStatisticCurrentValueByType(state, eventStatisticsKey, EventTypeField.Odds));
  const league = useSelector(state => state.Leagues[sportEvent.leagueId]);
  const [homeParticipant, awayParticipant] = useGetParticipantsByEvent(productId, drawNumber, eventNumber);
  const history = useHistory();
  const location = useLocation();
  const modalRef = useRef();
  const shouldOpenStatistic = Boolean(useQueryParam('openStatistic') !== null);
  const closeModal = useCloseModal();
  const results = sportEvent === null || sportEvent === void 0 ? void 0 : sportEvent.result[0];
  const onNavigateNext = useCallback(next => {
    const maxMatchCount = drawEvents.length;
    let nextEventNumber = eventNumber + next;
    if (nextEventNumber < 1) {
      nextEventNumber = maxMatchCount;
    } else if (nextEventNumber > maxMatchCount) {
      nextEventNumber = 1;
    }
    const search = new URLSearchParams(location.search);
    if (search.has('openStatistic')) {
      search.set('event', nextEventNumber);
    }
    history.replace({
      search: "?".concat(search)
    });
    modalRef.current.scrollTo(0, 0);
  }, [eventNumber, location.search, history, drawEvents.length]);
  if (extraMenuItems.length > 0) {
    extraMenuItems.forEach(item => {
      if (item.to.search.length) {
        item.to.search += '&openStatistic=';
      } else {
        item.to.search += 'openStatistic=';
      }
    });
  }
  const matchState = getSportInfoMatchState(sportEvent.sportEventStatus);
  const classNames = ['pg_detail_header', "pg_detail_header--".concat(productBranding)];
  const detailViewClassNames = ['pg_detail_view__menu'];
  if (hasMaskedOutcomes) {
    classNames.push('pg_detail_header--hidden');
    detailViewClassNames.push('pg_detail_view__menu--hidden');
  }
  return React.createElement(React.Fragment, null, React.createElement(TipsenModal, {
    Bottom: () => React.createElement(DetailsStickyBottom, {
      closeModal: closeModal,
      onNavigateNext: onNavigateNext
    }),
    isOpen: shouldOpenStatistic,
    ref: modalRef,
    scrollToEventOnClose: true
  }, React.createElement(BigStatBanner, {
    awayTeam: awayParticipant,
    className: classNames.join(' '),
    eventNumber: eventNumber,
    homeTeam: homeParticipant,
    leagueName: league === null || league === void 0 ? void 0 : league.name,
    matchId: matchId,
    matchState: matchState,
    odds: {
      one: odds[0],
      x: odds[1],
      two: odds[2]
    },
    productId: productId,
    results: results,
    sportEventStatus: sportEvent.sportEventStatus,
    sportType: league === null || league === void 0 ? void 0 : league.sportType,
    startTime: sportEvent.matchStart
  }), headerComponent && React.createElement("div", {
    className: "pg_detail_view__bet-buttons pg_detail_view__bet-buttons--".concat(productBranding)
  }, headerComponent), hasQuickBetActive && extraMenuItems.length && React.createElement("div", {
    className: detailViewClassNames.join(' ')
  }, React.createElement(ItemsMenu, {
    branding: "side-nav",
    className: "tipsen-side-nav-menu"
  }, extraMenuItems.map(_ref4 => {
    let {
      icon,
      selectedOnPaths,
      to,
      label,
      testIdSuffix
    } = _ref4;
    return React.createElement(LinkItemRoute, {
      Component: TipsenLink,
      "data-testid": "detail-view-mobile-".concat(testIdSuffix),
      icon: icon,
      iconPosition: "left",
      key: to.pathname,
      label: label,
      selectedOnPaths: selectedOnPaths,
      to: to
    });
  }))), React.createElement(Switch, null, extraMenuItems.filter(_ref5 => {
    let {
      to
    } = _ref5;
    return to.pathname.length > 0;
  }).map(_ref6 => {
    let {
      to,
      className,
      Component,
      props = {}
    } = _ref6;
    let absolutePath;
    if (routesMatch.path === '/') {
      absolutePath = to.pathname;
    } else {
      absolutePath = "".concat(routesMatch.path).concat(to.pathname);
    }
    return React.createElement(Route, {
      key: to.pathname,
      path: absolutePath
      ,
      render: () => React.createElement(Component, _extends({
        branding: productBranding,
        className: className
      }, props))
    });
  }), children)), React.createElement(Route, {
    path: gameGuidePaths,
    render: _ref7 => {
      let {
        match
      } = _ref7;
      return React.createElement(TipsenModal, {
        Bottom: () => React.createElement(ModalFooter, {
          routesMatch: routesMatch
        }),
        isOpen: Boolean(match)
      }, React.createElement(ModalHeader, null, "Spelguide"), React.createElement(LazyGameGuide, {
        branding: productBranding,
        matchId: matchId
      }));
    }
  }), React.createElement(Route, {
    path: oddsPaths,
    render: _ref8 => {
      let {
        match
      } = _ref8;
      return React.createElement(TipsenModal, {
        Bottom: () => React.createElement(ModalFooter, {
          routesMatch: routesMatch
        }),
        isOpen: Boolean(match)
      }, React.createElement(LazyOdds, {
        branding: productBranding
      }));
    }
  }));
};
setGlobal('svs.components.tipsen.detailViewMobileHandler.DetailViewMobileHandler', DetailViewMobileHandler);

 })(window);